<template>
  <div class="container crm">

    <Head></Head>
    <main>
      <div class="banner" style="background: #00BCD4;">
        <div class="wrap">
          <div class="txt">
            <h1>CRM系统</h1>
              <div class="subtitle">打破销售壁垒，助力企业高效经营业绩提升</div>
              <div>· 全流程销售自动化，提升企业销售效率促进成交</div>

              <div>· 多渠道客户统一管理，自定义客户标签，深度经营企业客户资产</div>

              <div>· 大数据分析，有效管控销售行为，提升销售行动力</div>
          </div>
          <div class="img">
            <img src="../assets/c4.png">
          </div>
        </div>
      </div>
      <ContBolck :title="cont9.title" :sub-title="cont9.subTitle">
        <div class="bg">
          <div class="wrap">
            <ul class="parent">
              <li v-for="(item,index) in list" :key="index">
                <figure class="item-wrap">
                  <div class="img">
                    <img :src="item.src">
                  </div>
                  <figcaption>
                    <h3 class="title">{{item.title}}</h3>
                    <div class="desc">{{item.desc}}</div>
                   
                  </figcaption>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </ContBolck>

      <div class="block wrap">
        <div class="info">
          <h2>全流程销售自动化</h2>
          <div class="text">提升销售效率促成交</div>
          <span class="sub-text">线索-客户-商机-合同回款报表"实现销售过程闭合管理，智能销售漏斗高效转化客户</span>
          
        </div>
        <div class="pic">
          <img src="../assets/crm04.jpg">
        </div>
      </div>
      <!--  -->
      <div class="bg2">
        <div class="block wrap">
          <div class="pic">
            <img src="../assets/crm06.jpg">
          </div>
          <div class="info">
            <h2>多种渠道营销线索</h2>
            <div class="text">统一管理高效便捷</div>
            <span class="sub-text">无缝对接电话机器人等</span>
           
          </div>
        </div>
      </div>
      <!--  -->
      <div class="block wrap">
        <div class="info">
          <h2>精细化客户管理</h2>
          <div class="text">深度挖掘商机</div>
          <span class="sub-text">自义定客户标签高效管理客户，帮助企业沉淀客户资产，实现客户的高效经营</span>
         
        </div>
        <div class="pic">
          <img src="../assets/crm07.jpg">
        </div>
      </div>
      <!--  -->
      <div class="bg2">
        <div class="block wrap">
          <div class="pic">
            <img src="../assets/crm08.jpg">
          </div>
          <div class="info">
            <h2>大数据管控销售行为</h2>
            <div class="text">提升销售行动力</div>
            <span class="sub-text">销售行动量管控、销售行为管控、销售leader实训辅导提升， 提升销售效率</span>
            
          </div>
        </div>
      </div>
      <div class="block wrap">
        <div class="info">
          <h2>可视化数据报表</h2>
          <div class="text">可视化数据报表</div>
          <span class="sub-text">无销售报表、业绩报表、管理报表等海量数据报表可视化呈现，为企业提供销售决策依据</span>
          
        </div>
        <div class="pic">
          <img src="../assets/crm09.jpg">
        </div>
      </div>
    </main>
    <Foot></Foot>
  </div>
</template>

<script>
  import ContBolck from '../components/contBolck'
  import Foot from '../components/foot'
  import Head from '../components/head'
  export default {
    components: {
      ContBolck,
      Foot,
      Head
    },
    data() {
      return {
        list: [{
          title: '销售过程管理',
          desc: '营销过程全自动化，提升效率',
          src: require('../assets/crm01.jpg')
        }, {
          title: '客户资源管理',
          desc: '多渠道统一管理，深度经营企业客户资源',
          src: require('../assets/crm02.jpg')
        }, {
          title: '销售行为管理',
          desc: '大数据分析，有效监控销售行为',
          src: require('../assets/crm03.jpg')
        }],
        cont9: {
          title: '强大的CRM系统',
          subTitle: '打破销售壁垒，助力企业高效经营，业绩提升'
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .bg {
    background: #f8f8f8;
    padding: 60px 0;
  }

  .bg2 {
    background: #f8f8f8;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    main {
      flex: 1;

      .banner {
        //background-image: url('../assets/banner-index1.png');
        background-position: center;
        background: #2979FF;

        .wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 520px;
          line-height: 36px;
          color: #FFF;

          h1 {
            font-size: 36px;
            font-weight: bold;
          }

          .subtitle {
            font-size: 20px;
          }

          .img {
            width: 35%;
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: flex-end;

            img {
              width: 100%;
            }
          }

        }
      }

      .parent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        li {
          padding: 20px;
          background: #fff;
          border-bottom: 2px solid #fff;
          position: relative;

          .img {
            overflow: hidden;
            position: relative;
          }

          figcaption {
            text-align: center;

            .title {
              margin-top: 40px;
              font-size: 18px;
              line-height: 36px;

            }

            .desc {
              font-size: 14px;
              margin-bottom: 20px;
              color: #acb2ba;
            }
          }

          img {
            width: 100%;
            transition: all 0.25s ease;
          }

          &::after {
            content: '';
            width: 0;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 50%;
          }

          &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);

            img {
              transform: scale(1.1);
            }

            &::after {
              width: 100%;
              left: 0;
              background: #2979FF;
              transition: all .8s;

            }
          }

          .project {
            text-align: center;
          }
        }
      }

      .block {
        padding: 100px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info {
          line-height: 40px;

          h2 {
            font-size: 36px;
            font-weight: bold;
          }

          .text {
            font-size: 20px;
          }

          .sub-text {
            color: #acb2ba;
          }

          .btn {
            margin-top: 20px;
          }
        }

        .pic {
          img {
            width: 400px;
          }
        }
      }
    }

  }
</style>
